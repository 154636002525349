<template>
	<div
		:id="id"
		v-qa="'builder-gridelement-gridembed'"
		class="grid-embed"
	>
		<iframe
			v-if="shouldRender"
			ref="gridEmbedIframeRef"
			v-qa="'builder-gridelement-gridembed-iframe'"
			class="grid-embed__iframe"
			:srcdoc="srcdoc"
			title="custom code element"
			v-bind="{ ...(isSandBoxed && { sandbox: `allow-scripts` }) }"
		/>
	</div>
</template>

<script setup lang="ts">
import {
	computed,
	onBeforeMount,
	onMounted,
	ref,
} from 'vue';

const props = withDefaults(defineProps<{
	srcdoc: string,
	shouldRender: boolean,
	isSandBoxed?: boolean,
	id: string,
}>(), {
	srcdoc: '',
	shouldRender: false,
});

const emit = defineEmits<{
	'iframe-height-updated': [number],
}>();

const gridEmbedIframeRef = ref<HTMLIFrameElement>();
const height = ref(0);
const heightStyle = computed(() => (height.value ? `${height.value}px` : 'auto'));

const heightUpdateEventListener = (event: MessageEvent) => {
	if (event.data.action === 'iframeHeightUpdated' && props.id === event.data.id && height.value !== event.data.height) {
		height.value = event.data.height;
		emit('iframe-height-updated', height.value);
	}
};

onMounted(() => {
	window.addEventListener('message', heightUpdateEventListener);
});

onBeforeMount(() => {
	window.removeEventListener('message', heightUpdateEventListener);
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.grid-embed {
	width: 100%;
	height: auto;

	&__iframe {
		width: 100%;
		height: v-bind(heightStyle);
		overflow: hidden;
		border: none;
	}
}

@include site-engine-mobile {
	.grid-embed {
		&__iframe {
			height: v-bind(heightStyle);
		}
	}
}
</style>
